/**
 * Bündelt alle Daten zu Vorlagen, Templates und Bürgschaftsdokumenten.
 * Wird ergänzt durch DokumentAGT.ts mit speziellem Kram für Auftraggebertexte (rund um Prüfung etc.)
 */
import i18n from '@/lang/i18nPlugin';
import type { Bürgschaftsart } from './Bürgschaft';
import type { Person } from './Partner';

/** Eine Vorlage, die der Auftraggeber hochlädt, damit daraus ein Template gemacht werden kann, mit dem dann schlussendlich Bürgschaften erstellt werden können */
export interface Bürgschaftsvorlage {
  id: number;
  auftraggeberId: string;
  bürgschaftsart: Bürgschaftsart;
  templatetyp: Templatetyp;
  gültigAb: string;
  dmsVorlageId: string;
  adressaten: string[];
  beschreibung: string;
}

export interface Archivierungsinformation {
  buergschaftsart: Bürgschaftsart;
  beschreibung: string;
  buergschaftstemplateId: string[];
  gueltigAb: string;
}

/**
 * Das Template, das aus einer Vorlage resultiert
 * Das hier ist die Oberklasse und wird überall verwendet, wo wir nicht wissen bzw. uns es nicht interessiert, welchen
 * konkreten Templatetyp das Template hat. Wenn die konkreten Datentypen für AGT, OET oder BGT benötigt werden, weil man
 * die speziellen Attribute benötigt, dann weiß man entweder welchen konkreten Typ man hat (weil man z.B. vom Backend
 * nur den konkreten Typ bekommt) oder man prüft auf das Attribut "templatetyp" und macht dann einen Typecast.
 */
export interface BürgschaftstemplateAllgemein {
  bürgschaftstemplateId: string;
  version: number;
  templatetyp: Templatetyp;
  bürgschaftsart: Bürgschaftsart;
  gültigAb: string;
  gültigBis: string;
  beschreibung: string;
  templatestatus: Templatestatus; // allgemeiner Templatestatus - ACHTUNG: konkrete Template-Typen haben teilweilse ihre eigene komplexe Logik
  templateVersion: string;
  dmsTemplateId: string;
  befristung: boolean; // Die Befristung hängt am Templatenutzer, der im Frontend nicht abgebildet wird
  ansprechPartner: Person;
  // Unklar ob es wirklich existiert, wird aber genutzt in src/buerge/components/buergschaftstexte/BuergschaftstexteListe.vue Zeile 96
  auftraggeberId: string;
}

export function createEmptyBuergschaftstemplateAllgemein(): BürgschaftstemplateAllgemein {
  return {
    bürgschaftstemplateId: '',
    version: null,
    templatetyp: null,
    bürgschaftsart: null,
    gültigAb: '',
    gültigBis: '',
    beschreibung: '',
    templatestatus: null,
    templateVersion: '',
    dmsTemplateId: '',
    befristung: null,
    ansprechPartner: null,
    auftraggeberId: '',
  };
}

export interface BuergschaftstemplateDetailsFuerAufgabenListe {
  buergschaftstemplateId: string;
  buergschaftsart: Bürgschaftsart;
}

/** Der Typ des Templates */
export enum Templatetyp {
  AGT = 'AGT', // Auftraggeber-Template
  OET = 'OET', // Öffentliches Template
  BGT = 'BGT', // Bürgen-Template/ Haustext
}

export const TemplatetypAnzeigetextMap: Map<Templatetyp, string> = new Map([
  [Templatetyp.AGT, 'Auftraggebertexte'],
  [Templatetyp.OET, 'Öffentliche Texte'],
  [Templatetyp.BGT, 'Bürgentexte'],
]);

export const TemplatetypAnzeigetextTextartDativMap: Map<Templatetyp, string> = new Map([
  [Templatetyp.AGT, 'Eigene Vorlage'],
  [Templatetyp.OET, 'Öffentlichen Text'],
  [Templatetyp.BGT, 'Bürgentext'],
]);

export const TemplatetypAnzeigetextTextartMap: Map<Templatetyp, string> = new Map([
  [Templatetyp.AGT, 'Auftraggebertext'],
  [Templatetyp.OET, 'Öffentlicher Text'],
  [Templatetyp.BGT, 'Bürgentext'],
]);

export const TemplatetypAnzeigetextTextartMapPlural: Map<Templatetyp, string> = new Map([
  [Templatetyp.AGT, 'Eigenen Vorlagen'],
  [Templatetyp.OET, 'Öffentlichen Texten'],
  [Templatetyp.BGT, 'Bürgentexten'],
]);

/**
 * Ein Template hat eine Änderungshistorie. Dies ist ein Eintrag aus der Historie zu einer konkreten Änderung bzw. einem Zeitpunkt in der Historie.
 * Besonderheit: Dies umfasst nicht nur die Historie des Templates, sondern auch die Vorlage und die Prüfungen.
 */

export interface HistorieneintragAGT {
  bearbeiterName: string;
  bearbeitungsZeitpunkt: Date;
  historieneintragTyp: HistorieneintragTyp;
  erstPrüferKommentar: string;
  zweitPrüferKommentar: string;
  externerKommentar: string;
  erstPrüferName: string;
  erstPrüferKommentarZeitpunkt: Date;
}

export interface Historieneintrag {
  bearbeiterName: string;
  bearbeitungsZeitpunkt: Date;
  historieneintragTyp: HistorieneintragTyp;
}

export enum HistorieneintragTyp {
  TEMPLATE_ARCHIVIER = 'TEMPLATE_ARCHIVIERT',
  // Für Auftraggebertext
  BÜRGSCHAFTSVORLAGE_HOCHGELADEN = 'BÜRGSCHAFTSVORLAGE_HOCHGELADEN',
  TEMPLATE_ERSTFERTIGSTELLUNG_DURCH_TRUSTLOG = 'TEMPLATE_ERSTFERTIGSTELLUNG_DURCH_TRUSTLOG',
  TEMPLATE_FERTIGGESTELLT_DURCH_TRUSTLOG = 'TEMPLATE_FERTIGGESTELLT_DURCH_TRUSTLOG',
  ERSTPRÜFUNG_AKZEPTIERT = 'ERSTPRÜFUNG_AKZEPTIERT',
  ERSTPRÜFUNG_ABGELEHNT = 'ERSTPRÜFUNG_ABGELEHNT',
  ZWEITPRÜFUNG_AKZEPTIERT = 'ZWEITPRÜFUNG_AKZEPTIERT',
  ZWEITPRÜFUNG_ABGELEHNT = 'ZWEITPRÜFUNG_ABGELEHNT',
  FREIGEGEBEN = 'FREIGEGEBEN',
  ABGELEHNT = 'ABGELEHNT',

  // Für Öffentliche- und Bürgentext
  TEMPLATE_ERSTELLT = 'TEMPLATE_ERSTELLT',
  TEMPLATE_FERTIGGESTELLT = 'TEMPLATE_FERTIGGESTELLT',
  TEMPLATE_AKTIVIERT = 'TEMPLATE_AKTIVIERT',
}

/** Templatestatus - ein "Highlevel"-Status, der nicht detailliert auf die Prüfungsstatus eingeht */
export enum Templatestatus {
  /** In Bearbeitung */
  B = 'B',

  /** In Prüfung bei der Trustlog - Nur für Auftraggebertexte */
  PT = 'PT',

  /** Fertiggestellt durch BSP */
  F = 'F',

  /** In Prüfung beim Bürgen */
  P = 'P',

  /** Aktiv */
  A = 'A',

  /** Abgelehnt */
  AB = 'AB',

  /** Inaktiv - gibt es aktuell noch nicht! */
  IA = 'IA',

  // Hacks für's Frontend, um bestimmte Zustände bei Auftraggebertexten abzubilden
  H = 'H', // Es gibt natürlich keinen Template-Status "Hochgeladen", aber es ist convenient, diesen Pseudo-Status hier aufzunehmen - sonst hätte man an anderer Stelle Sonderlocken. Einen Tod muss man sterben.
  AN = 'AN',
  NULL_VON_Y = '0_y',
  X_VON_Y = 'x_y',
  Y_VON_Y = 'y_y',
}

/** Bildet den Templatestatus auf einen Anzeigetext ab */
export const TemplateStatusAnzeigetextMap: Map<Templatestatus, string> = new Map([
  [
    Templatestatus.B,
    i18n.global.t('buerge.buergschaftstexte.liste.filter.filterOptions.status[0]'),
  ],
  [
    Templatestatus.PT,
    i18n.global.t('buerge.buergschaftstexte.liste.filter.filterOptions.status[0]'),
  ],
  [
    Templatestatus.F,
    i18n.global.t('buerge.buergschaftstexte.liste.filter.filterOptions.status[1]'),
  ],
  [
    Templatestatus.P,
    i18n.global.t('buerge.buergschaftstexte.liste.filter.filterOptions.status[2]'),
  ],
  [
    Templatestatus.A,
    i18n.global.t('buerge.buergschaftstexte.liste.filter.filterOptions.status[1]'),
  ],
  [
    Templatestatus.AB,
    i18n.global.t('buerge.buergschaftstexte.liste.filter.filterOptions.status[3]'),
  ],
  [
    Templatestatus.IA,
    i18n.global.t('buerge.buergschaftstexte.liste.filter.filterOptions.status[4]'),
  ],

  // Eigentlich kein Templatestatus, aber ok. Siehe Enum Templatestatus
  [
    Templatestatus.H,
    i18n.global.t('buerge.buergschaftstexte.liste.filter.filterOptions.status[5]'),
  ],
  [
    Templatestatus.AN,
    i18n.global.t('buerge.buergschaftstexte.liste.filter.filterOptions.status[6]'),
  ],
]);

/**
 * Gibt die (aufsteigende) Sortierreihenfolge für Templatestatus zurück.
 * Das könnte je nach Domäne etwas anders aussehen. Es werden auch nicht unbedingt alle Status angezeigt (was für die
 * Sortierung dann aber egal sein sollte).
 */
export const TemplatestatusWertigkeitMap: Map<Templatestatus, number> = new Map([
  [Templatestatus.H, 0],
  [Templatestatus.B, 1],
  [Templatestatus.PT, 2],
  [Templatestatus.F, 3],
  [Templatestatus.P, 4],
  [Templatestatus.NULL_VON_Y, 5],
  [Templatestatus.X_VON_Y, 6],
  [Templatestatus.Y_VON_Y, 7],
  [Templatestatus.A, 8],
  [Templatestatus.AB, 9],
  [Templatestatus.IA, 10],
]);
