import type { LocaleSchema } from '@/lang/types';

const deLocale: LocaleSchema = {
  shared: {
    profil: {
      nutzerstatus: {
        beschreibungBerechtigung:
          'Mit einem aktiven Status sind Sie berechtigt die Bürgschaftsplattform zu nutzen.',
        beschreibungDeaktivierung:
          'Durch Deaktivierung haben Sie keinen Zugriff mehr auf die Plattform.',
      },
    },
    tabelle: {
      filter: {
        filterLoeschen: 'Filter löschen',
        ausgewaehlt: 'ausgewählt',
        auswahlLoeschen: 'Auswahl löschen',
        anwenden: 'Anwenden',
        zuruecksetzen: 'Filter zurücksetzen',
        von: 'von',
        bis: 'bis',
      },
      pagination: {
        seite: 'Seite',
        proSeite: 'pro Seite',
      },
      emptyStateText: 'Keine entsprechenden Ergebnisse wurden gefunden.',
    },
    zurueck: 'Zurück',
    absenden: 'Absenden',
    senden: 'Senden',
    speichern: 'Speichern',
    abbrechen: 'Abbrechen',
    weiter: 'Weiter',
    hinzufuegen: 'Hinzufügen',
    erfolgreichhinzugefuegt: 'wurde erfolgreich hinzugefügt.',
    loeschen: 'Ja, löschen',
    nutzereinstellung: 'Zur Nutzereinstellung',
    alleauswaehlen: 'Alle auswählen',
    alleabwaehlen: 'Alle abwählen',
    unternehmen: 'Unternehmen',
    adresse: 'Adresse',
    keineEingabe: '[Keine Eingabe]',
    aktionen: 'Aktionen',
    historie: 'Historie',
    ablehnen: 'Ablehnen',
    akzeptieren: 'Akzeptieren',
    schliessen: 'Schließen',
    aktualisieren: 'Aktualisieren',
    zuDenAufgaben: 'Zu den Aufgaben',
    dokumentationHerunterladen: 'Beschlussdokumentation herunterladen',
    gesperrtPopup:
      'Diese Ansicht wurde zwischenzeitlich bearbeitet. Bitte aktualisieren Sie die Seite.',
    zeichen: 'Zeichen',
    von: 'von',
    navigation: {
      hilfe: 'Hilfe und Support',
      abmelden: 'Abmelden',
      sitzungsdauer: 'Sitzungsdauer',
    },
    nutzerundrollen: {
      ueberschrift: 'Nutzer und Rollen',
      nutzer: {
        ueberschrift: 'Nutzer',
        hinzufuegen: 'Neuer Nutzer',
        neuernutzerhinzu: 'Neuen Nutzer hinzufügen',
        nutzerinnen: 'Nutzer:innen',
        zuallennutzern: 'Zu allen Nutzern',
        warnung: {
          bereitsvorhanden: {
            header: 'Nutzer bereits vorhanden',
            text: 'Die von Ihnen angegebenen E-Mail Adresse wurde bereits zur Anlage eines Nutzers Ihres Unternehmens verwendet.\n              Bitte ändern Sie die E-Mail Adresse oder brechen Sie den Prozess ab.',
          },
          systemrolle: {
            header: 'Systemrolle zugewiesen',
            text: 'Dieser Nutzer kann nicht gelöscht werden, da diesem aktuell eine nicht bearbeitbare Systemrolle\n              zugewiesen ist. Um den Nutzer zu löschen, weisen Sie ihm bitte eine andere Rolle zu. Achtung, dies\n              hat möglicherweise Auswirkungen auf die Funktionsweise der Plattform.',
          },
          aenderung: {
            text: 'Bitte nehmen Sie mindestens eine Änderung vor, um den Nutzer speichern zu können\n              oder brechen Sie den Prozess ab.',
          },
          wiederhergestellt: {
            header: 'E-Mail-Adresse wurde bereits verwendet',
            text: 'Die von Ihnen angegebenen E-Mail Adresse wurde bereits zur Anlage eines Nutzers Ihres Unternehmens verwendet.\n              Der entsprechende Nutzer wurde gelöscht.  Bitte ändern Sie die E-Mail Adresse, stellen Sie den Nutzer wieder\n              her oder brechen Sie den Prozess ab.',
            button1Text: 'Konto wiederherstellen',
            frage: 'Möchten Sie diesen Nutzer wirklich wiederherstellen?',
            buttonja: 'Ja, wiederherstellen',
          },
          erfolg: {
            nutzerGeloescht: 'Das Löschen des Nutzerprofils {vorname} {nachname} war erfolgreich.',
            nutzerGespeichert:
              'Die Änderungen an dem Nutzerprofil {vorname} {nachname} wurden gespeichert.',
          },
        },
        aenderung: {
          status: 'Durch Aktivieren ist der Nutzer berechtigt, digitale Bürgschaften zu verwalten.',
          aktiv: 'Aktiv',
          inaktiv: 'Inaktiv',
          beschreibungBerechtigung:
            'Durch Aktivieren ist der Nutzer berechtigt, digitale Bürgschaften zu verwalten.',
          beschreibungDeaktivierung:
            'Durch Deaktivierung hat der Nutzer keinen Zugriff mehr auf die Plattform.',
          speichern: 'Nach dem Speichern wird der entsprechende Status übernommen.',
          gespeichert:
            'Die Änderungen an dem Nutzerprofil {vorname} {nachname} wurden gespeichert.',
          loeschen: 'Das Löschen des Nutzerprofils {vorname} {nachname} war erfolgreich.',
          angepasst: 'Die Änderungen an dem Nutzerprofil {vorname} {nachname} wurden angepasst.',
          deaktiviert: 'Der Nutzer {vorname} {nachname} wurden deaktiviert.',
          reaktivieren:
            'Der Nutzer kann wieder reaktiviert werden und wieder Zugriff auf die Plattform erhalten.',
          ueberschrift: 'Aktivitätsstatus des Nutzers',
        },
        loeschen: 'Nutzer loeschen',
        bearbeiten: 'Nutzer bearbeiten',
        zuallenutzern: 'Zu allen Nutzern',
      },
      rollen: {
        ueberschrift: 'Rollen',
        ueberschrifthinzufuegen: 'Neue Rolle hinzufügen',
        hinzufuegen: 'Neue Rolle',
        nutzerrolle: 'Nutzerrolle',
        rollenbezeichnung: 'Bezeichnung der Rolle',
        zugeordnetenutzer: 'Zugeordnete Nutzer',
        aenderungswarnung:
          'Bitte nehmen Sie mindestens eine Änderung vor, um die Rolle speichern zu können\n              oder brechen Sie den Prozess ab.',
        loeschen: 'Rolle löschen',
        loeschenfrage: 'Möchten Sie wirklich die Rolle löschen?',
        loeschenunmoeglich:
          'Diese Rolle kann nicht gelöscht werden, da ihr noch mindestens ein Nutzer zugeordnet ist.\n                Um die Rolle zu löschen, wechseln Sie bitte zu den Nutzereinstellungen und entfernen Sie\n                alle zugeordneten Nutzer.',
        rollenname: 'Name der Rolle',
        allerollen: 'Zu allen Rollen',
        formular: {
          name: 'Wie soll die neue Rolle heißen?',
          rollenname: 'Name der Rolle',
          avatar: 'Wählen Sie einen Avatar für die Rolle',
          avatarfehler: 'Bitte wählen Sie einen Avatar aus',
          berechtigung: 'Wählen Sie die Berechtigungen für die neue Rolle',
        },
        rechtegruppe: {
          anträge: 'Anträge',
          bürgschaften: 'Bürgschaften',
          aufgaben: 'Aufgaben',
          mitteilungen: 'Mitteilungen',
          projekte: 'Projekte',
          unternehmen: 'Unternehmen',
          verträge: 'Verträge',
          texte: 'Texte',
          nutzer: 'Nutzer',
          rollen: 'Rollen',
        },
      },
    },
    kontaktdaten: {
      ueberschrift: 'Kontaktdaten',
      automatischAusfuellen: 'Kontaktdaten automatisch ausfüllen',
      vorname: 'Vorname',
      nachname: 'Nachname',
      email: 'E-Mail Adresse',
      telefonnummer: 'Telefonnummer',
      telefonnummerOptional: 'Telefonnummer (optional)',
    },
    ansprechpartner: {
      stammdaten: 'Stammdaten',
      header: 'Ihre Ansprechpartner für Rückfragen',
      name: 'Name',
      email: 'Email',
      telefonnummer: 'Telefonnummer',
    },
    pdfEmbed: {
      keineUnterstuetzung: 'Ihr Browser unterstützt kein PDF. ',
      falscherDokumentyp: 'Der Dokumententyp der Vorlage kann nicht im Browser dargestellt werden.',
      downloadText: 'Hier ist der ',
      downloadLink: 'Download-Link',
    },
  },
  auftraggeber: {
    aufgaben: {
      liste: {
        contentHeader: 'Aufgaben',
      },
    },
    buergschaften: {},
    buergschaftsantraege: {},
    buergschaftstexte: {},
    mitteilungen: {},
    nutzer: {},
    projekte: {},
    unternehmen: {},
    vertraege: {},
  },
  buerge: {
    navigation: {
      label: {
        arbeitsbereich: 'Mein Arbeitsbereich',
        aval: 'Avalverwaltung',
        teilnehmer: 'Teilnehmerverwaltung',
        unternehmen: 'Mein Unternehmen',
      },
      title: {
        aufgaben: 'Aufgaben',
        avale: 'Avale',
        auftraege: 'Aufträge',
        texte: 'Texte',
        teilnehmer: 'Teilnehmer',
        vertraege: 'Avalkreditverträge',
        nutzer: 'Nutzer und Rollen',
      },
    },
    aufgaben: {
      liste: {
        contentHeader: 'Aufgaben',
        filter: {
          label: ['Betreff', 'Eingangsdatum'],
          filterOptions: {
            betreff: [
              'Auftraggebertext Erstprüfung',
              'Auftraggebertext Zweitprüfung',
              'Avaldokument hochladen',
              'Aval korrigieren',
              'Bürgschaftsablehnung bestätigen',
              'Unterlagen Inanspruchnahme herunterladen',
              'Neue Vertragszuordnung prüfen',
              'Auftrag prüfen',
            ],
          },
        },
        spalten: {
          header: {
            betreff: 'Betreff / Eingangsdatum',
            absender: 'Absender',
          },
          betreff: [
            'Neuen {textart} prüfen',
            'Neuen Auftraggebertext prüfen',
            'Zweitprüfung {textart}',
            'Zweitprüfung Auftraggebertext',
            'Auftrag prüfen',
            'Avaldokument hochladen',
            'Aval korrigieren',
            'Bürgschaftsablehnung bestätigen',
            'Unterlagen zur Inanspruchnahme herunterladen',
            'Neue Vertragszuordnung prüfen',
          ],
          absender: {
            beguenstigter: 'Begünstigter',
            kreditnehmer: 'Kreditnehmer',
            trustlog: 'Trustlog (System)',
          },
        },
        defaultEmptyStateText:
          'Hier werden Ihre Aufgaben angezeigt, sobald Texte hinterlegt oder Bürgschaften von Ihren Nachunternehmern eingereicht wurden.',
      },
    },
    buergschaften: {},
    buergschaftsantraege: {},
    buergschaftstexte: {
      liste: {
        contentHeader: 'Texte',
        tab: {
          beguenstigtentexte: 'Begünstigtentexte',
          avalgebertexte: 'Avalgebertexte',
          oeffentlichetexte: 'Öffentliche Texte',
        },
        suchPlaceholder: 'Suche nach Name oder ID des Begünstigten',
        filter: {
          label: ['Avalart', 'Eingegangen', 'Gültig Ab', 'Status'],
          filterOptions: {
            avalart: [
              'Mängelansprüchebürgschaft',
              'Vertragserfüllungsbürgschaft',
              'An- & Vorauszahlungsbürgschaft',
              'Ausführungsbürgschaft',
              'Anzahlungen',
              'Arbeitszeitkontenbürgschaft',
              'Auslandsbürgschaft',
              'Ausschüttungsbürgschaft',
              'Bauhandwerkersicherungsbürgschaft',
              'Bietungsbürgschaft',
              'BImSchG-Bürgschaft',
              'Branntweinbürgschaft',
              'Deckungs-/Haftungsrücklass',
              'Deckungsrücklass',
              'Erschließungsbürgschaft',
              'Fertigstellungs-/Haftungsrücklass',
              'Fertigstellungsgarantie',
              'Frachtbürgschaft',
              'Garantie',
              'Gewährleistungsgarantie',
              'Haftungsrücklass',
              'IATA-Bürgschaft',
              'Lieferantenbürgschaften',
              'MaBV-Bürgschaft',
              'Mietbürgschaft',
              'Mineralölbürgschaft',
              'Pachtbürgschaft',
              'Prozessbürgschaft',
              'Rekultivierungsbürgschaft',
              'Rückbürgschaft',
              'Sonstige Bürgschaft',
              'Sonstige Garantie',
              'Tankkartenbürgschaft',
              'Vertragserfüllungsgarantie',
              'Zahlungsbürgschaft',
              'Zollbürgschaft',
            ],
            status: [
              'In Bearbeitung',
              'Aktiv',
              'In Prüfung',
              'Abgelehnt',
              'Archiviert',
              'Hochgeladen',
              'Angelegt',
            ],
          },
        },
        spalten: {
          beguenstigter: 'Begünstigter / Bezeichnung',
          avalart: 'Avalart',
          textId: 'Text-ID',
          gueltigAb: 'Gültig ab',
          status: 'Status',
          textbezeichnung: 'Textbezeichnung',
        },
      },
      uebersicht: {
        contentHeader: {
          auftraggebertext: 'Auftraggebertext im Detail',
          neuePruefung: 'Neuen {textart} prüfen',
          zweitPruefung: 'Zweitprüfung {textart}',
          buergentext: 'Bürgentext im Detail',
          oeffentlicherText: 'Öffentlicher Text im Detail',
        },
        banner: {
          header: 'Konflikt beim Bearbeiter',
          text: 'Die Erst- und Zweitprüfung für einen Auftraggebertext kann nicht durch den selben Nutzer erfolgen!',
        },
        allgemeineAngaben: 'Allgemeine Angaben',
        status: 'Status',
        gueltigAb: 'Gültig Ab',
        textId: 'Text-Id',
        bezeichnung: 'Bezeichnung',
        dmsId: 'Dms-Id Template',
        beguenstigter: 'Begünstigter',
        details: 'Details',
        befristung: 'Befristung',
        befristet: 'Befristet',
        unbefristet: 'Unbefristet',
        aktionen: {
          akzeptieren: 'Akzeptieren',
          ablehnen: 'Ablehnen',
          pdfExportieren: 'Als PDF exportieren',
          herunterladen: 'Beschlussdokumentation herunterladen',
        },
        historie: {
          hochladen: '{bearbeiter} hat die Vorlage Auftraggebertext {beschreibung} hochgeladen.',
          erstellen: '{bearbeiter} hat den Auftraggebertext erstellt.',
          bgtUndOetErstellen: '{bearbeiter} hat den Text erstellt.',
          archiviert: '{bearbeiter} hat die Vorlage Auftraggebertext {beschreibung} archiviert.',
          akzeptiert: '{bearbeiter} hat den Auftraggebertext {beschreibung} {link} akzeptiert.',
          mitKommentar: 'mit Kommentar',
          abgelehntOderZurueckweisen:
            '{bearbeiter} hat den Auftraggebertext {beschreibung} {link} zurückgewiesen.',
          freigegeben: '{bearbeiter} hat den Auftraggebertext {beschreibung} {link} freigegeben.',
          kommentar: {
            title: 'Kommentar',
            internerKommentar: 'Interne Kommentare',
            externerKommentar: 'Kommentar an den Auftraggeber',
          },
        },
        pruefenSnackbar: {
          erstpruefung: 'Neuen Text prüfen',
        },
      },
      pruefung: {
        title: 'Interner Kommentar',
        kommentarPlaceholder: 'Kommentar verfassen ...',
        erstpruefung: {
          akzeptieren: {
            beschreibung:
              'Bitte fügen Sie einen internen Kommentar zum Begünstigtentext hinzu. Anschließend wird der Text zur Zweitprüfung weitergeleitet.',
            kommentarPlaceholder:
              'Hier können Sie einen Kommentar an den weiteren Prüfer verfassen ...',
            primaryButton: 'Hinzufügen & abschließen',
          },
          ablehnen: {
            beschreibung:
              'Bitte geben Sie an, warum Sie den Begünstigtentext ablehnen. Anschließend wird der Text an den Zweitprüfer weitergeleitet.',
            title: 'Interner Kommentar (nicht editierbar)',
            KommentaPlaceholder: 'Bitte erfassen Sie einen Kommentar an den Zweitprüfer...',
            kommentarAnAgTitle: 'Nachricht an den Begünstigten (editierbar vom Zweitprüfer)',
            kommentarAnAgPlaceholder: 'Bitte erfassen Sie eine Nachricht an den Begünstigten...',
            primaryButton: 'Weiterleiten',
          },
        },
        zweitpruefung: {
          akzeptieren: {
            beschreibung:
              'Bitte fügen Sie einen internen Kommentar zum akzeptierten Begünstigtentext hinzu.',
            primaryButton: 'Text freigeben',
          },
          ablehnen: {
            kontaktdatenTitle: 'Kontaktdaten des Ansprechpartners (optional)',
            kontaktdatenBeschreibung:
              'Bitte nennen Sie einen Ansprechpartner, an den sich der {rolle} bei Rückfragen wenden kann.',
            beschreibung:
              'Bitte geben Sie an, warum Sie den Begünstigtentext ablehnen. Der vorbereitete Kommentar des Erstprüfers kann übernommen und/oder editiert an den Begünstigten gesendet werden.',
            kommentarAnAgTitle: 'Kommentar an den Begünstigten (editierbar)',
          },
        },
        erfolgScreen: {
          erstpruefung:
            'Sie haben den Begünstigtentext {entscheidung} und zur Zweitprüfung weitergeleitet.',
          zweitpruefung: 'Sie haben den Begünstigtentext {entscheidung}.',
        },
      },
    },
    nutzerundrollen: {
      nutzer: {
        aenderung: {
          status: 'Durch Aktivieren ist der Nutzer berechtigt, digitale Avale zu verwalten.',
        },
      },
      rollen: {
        rechtegruppe: {
          anträge: 'Anträge',
          bürgschaften: 'Avale',
          aufgaben: 'Aufgaben',
          mitteilungen: 'Mitteilungen',
          projekte: 'Projekte',
          unternehmen: 'Teilnehmer',
          verträge: 'Avalkreditverträge',
          texte: 'Texte',
          nutzer: 'Nutzer',
          rollen: 'Rollen',
        },
        rechte: {
          dokumenthochladen: 'Avaldokument hochladen',
        },
      },
    },
    profil: {
      nutzerstatus: {
        beschreibungBerechtigung:
          'Mit einem aktiven Status sind Sie berechtigt die Avalplattform zu nutzen.',
        beschreibungDeaktivierung:
          'Durch Deaktivierung haben Sie keinen Zugriff mehr auf die Plattform.',
      },
    },
    unternehmen: {
      liste: {
        contentHeader: 'Teilnehmer',
        suchPlaceholder: 'Alle Teilnehmer durchsuchen',
        spalten: {
          teilnehmer: 'Teilnehmer',
          anschrift: 'Anschrift',
          teilnehmerId: 'Teilnehmer-ID',
        },
        aktionen: {
          exportieren: 'Liste exportieren',
        },
        defaultEmptyStateText:
          'Es sieht so aus, als wurden noch keine weiteren Unternehmen hinzugefügt.',
      },
      uebersicht: {
        contentHeader: 'Teilnehmer-ID',
        aktionen: {
          avalErstellen: 'Aval ausstellen',
        },
        detailansicht: {
          ueberschrift: 'Stammdaten',
          name: 'name des teilnehmers',
          anschrift: 'anschrift',
          gesellschaftsform: 'gesellschaftsform',
          handelsregisternummer: 'handelsregisternummer',
          handelsregister: 'handelsregister',
        },
        tabelle: {
          ueberschrift: {
            mutterUnternehmen: 'Trustlog-Vertragspartner',
            tochterUnternehmen: 'Tochterunternehmen',
          },
        },
      },
    },
    vertraege: {
      vertragsnummer: 'Vertragsnummer',
      kreditnehmer: 'Kreditnehmer',
      anschrift: 'Anschrift',
      aktivierungsstatus: 'Status',
      allgemeineAngaben: 'Allgemeine Angaben',
      verknuepfungenInPruefung: 'Verknüpfungen in Prüfung',
      verknuepfteUnternehmen: 'Verknüpfte Unternehmen',
      abgelehnteVerknuepfungen: 'Abgelehnte Verknüpfungen',
      dokumentationHerunterladen: 'Dokumentation herunterladen',
      vertragszuordnungPruefen: 'Vertragszuordnung prüfen ',
      zuVerknuepfendeUnternehmen: 'Zu verknüpfende Unternehmen',
      bitteAlleUnternehmenAuswaehlen:
        'Bitte wählen Sie alle Unternehmen aus, welche zu dem Vertrag gehören.',
      neueVertragzuordnungPruefen: 'Neue Vertragzuordnung prüfen',
      neueVertragzuordnungGeprueft: 'Neue Vertragszuordnung geprüft',
      vertragsdetails: 'Vertragsdetails',
      internerKommentar: 'Interner Kommentar (optional)',
      KommentarAnDenKreditnehmer: 'Kommentar an den Kreditnehmer',
      KommentarVerfassen: 'Kommentar verfassen ...',
      unternehmenAusgewaehlt: 'Unternehmen ausgewählt',
      zuDenVertraegen: 'Zu den Verträgen',
      moeglichkeitKommentarErfassen:
        'Sie haben die Möglichkeit einen Kommentar zur Freigabe der weiteren Unternehmen zu erfassen.',
      zuordnung: {
        aendern: {
          ueberschrift: {
            aenderung: 'Warum möchten Sie die Verknüpfungen ändern?',
            ablehnung: 'Warum möchten Sie die weiteren Verknüpfungen ablehnen?',
          },
          beschreibung: {
            aenderung:
              'Sie können in den Kommentarfeldern Ihre abgeänderte Unternehmensauswahl begründen.',
            ablehnung:
              'Sie können in den Kommentarfeldern begründen, warum Sie weitere Verknüpfungen ablehnen.',
          },
        },
        pruefenErfolg: {
          beschreibung: {
            akzeptiert: 'Zuordnung der weiteren Unternehmen wurde freigegeben.',
            abgelehnt: 'Zuordnung der weiteren Unternehmen wurde freigegeben.',
          },
          subbeschreibung: {
            akzeptiert:
              'Sie haben die Zuordnung der weiteren Unternehmen bestätigt und diese freigegeben.',
            abgelehnt:
              'Der Auftragnehmer wird über Ihre Entscheidung informiert und wird bei Bedarf mit Ihnen in Verbindung treten.',
          },
        },
      },
      liste: {
        contentHeader: 'Avalkreditverträge',
        filter: {
          label: ['Bürge', 'Kreditnehmer', 'Status'],
        },
      },
      details: {
        contentHeader: 'Details zum Vertrag',
      },
    },
  },
  trustlog: {
    aufgaben: {},
    buergschaften: {},
    buergschaftsantraege: {},
    buergschaftstexte: {},
    nutzer: {},
    stammdaten: {},
  },
};

export default deLocale;
